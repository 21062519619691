import React from "react";

const LazyLoader = () => {

    return (
        <div id="preloader">
            <div id="ctn-preloader" className="ctn-preloader">
                <div className="animation-preloader">
                    <div className="txt-loading">
                        <span data-text-preloader="A" style={{ color: "#1A4645" }} className="letters-loading">
                            A
                        </span>
                        <span data-text-preloader="N" style={{ color: "#1A4645" }} className="letters-loading">
                            N
                        </span>
                        <span data-text-preloader="S" style={{ color: "#1A4645" }} className="letters-loading">
                            S
                        </span>
                        <span data-text-preloader="O" style={{ color: "#1A4645" }} className="letters-loading">
                            O
                        </span>
                        <span data-text-preloader="F" style={{ color: "#1A4645" }} className="letters-loading">
                            F
                        </span>
                        <span data-text-preloader="T" style={{ color: "#1A4645" }} className="letters-loading">
                            T
                        </span>
                        <span data-text-preloader="T" style={{ color: "#1A4645" }} className="letters-loading">
                            T
                        </span>
                    </div>

                </div>

            </div>
        </div>
    );

};

export default LazyLoader;