import React, { useEffect, useLayoutEffect, Suspense } from "react";
import { HashRouter as Router, Routes, Route, useLocation } from "react-router-dom";
/*------ Pages-----*/
import LazyLoader from "./Pages/LazyLoader";
// import DigitalMarketing from "./Pages/Digital-marketing";
// import PaymentProcessing from "./Pages/Payment-processing";
// import HRManagement from "./Pages/HR-Management";
// import Startup from "./Pages/Startup";
// import HomeCRM from "./Pages/Home-CRM";

// import About from "./Pages/About";
// import Team from "./Pages/Team";
// import BusinessERP from "./Pages/BusinessERP";
// import BusinessCRM from "./Pages/BusinessCRM";
// import BusinessPOS from "./Pages/BusinessPOS";
// import Trail from "./Pages/Trail";
// import Bloglist from "./Pages/Bloglist";

// // Blog-Detail
// import CrmBlogDetail from "./Pages/CrmBlogDetail";
// import ErpBlogDetail from "./Pages/ErpBlogDetail";
// import PosBlogDetail from "./Pages/PosBlogDetail";

// Features-ERP
// import Administration from "./Pages/Administration";
// import Hrms from "./Pages/Hrms";
// import AccountAndFinance from "./Pages/AccountAndFinance";
// import PurchasingAndOperation from "./Pages/PurchasingAndOperation";
// import Sale from "./Pages/Sales";
// import InventoryAndManagement from "./Pages/InventoryAndManagement";
// import ProjectAndManagement from "./Pages/ProjectAndManagement";
// import Pos from "./Pages/Pos";
// import SalesERP from "./Pages/SalesERP";

// features-CRM
// import Sales from "./Pages/Sales";
// import Support from "./Pages/Support";
// import RealAndEstate from "./Pages/RealAndEstate";
// import Shipping from "./Pages/Shipping";

// import BlogSingle from "./Pages/BlogSingle";
// import Contact from "./Pages/Contact";
// import Route from "./Route";
// import Landing from "./Pages/Landing";
// import homesupport from "./Pages/home-support";
// import HomeERP from "./Pages/Home-ERP";

// import homeHosting from "./Pages/HomeHosting";
// import homeSecurity from "./Pages/HomeSecurity";
// import homeSoftwareDark from "./Pages/Home-software-dark";
// import HomeAppShowcase from "./Pages/Home-app-showcase";
// import HomeCloud from "./Pages/Home-cloud";
// import HomeTracking from "./Pages/Home-tracking";
// import HomeEvent from "./Pages/Home-event";
// import HomeChat from "./Pages/Home-chat";

// import POSSubscribes from "./components/POSSubscribesStripe";
// import Faq from "./Pages/Faq";
// import ServiceDetails from "./Pages/ServiceDetails";
// import SignIn from "./Pages/SignIn";
// import SignUp from "./Pages/SignUp";
// import BlogGridPage from "./Pages/BlogGridPage";
// import NotFound from "./Pages/404";
// import ForgetPass from "./components/ForgetPass";
// import PrivacyPolicy from "./components/PrivacyPolicy";
// import TermAndConditions from "./components/TermAndConditions";
// import RefundPolicy from "./components/RefundPolicy";
// import TrialPolicy from "./components/TrialPolicy";

// import Subscribes from "./components/Subscribes";
// import Constants from "./utils/constants";

// Features-POS
// import Restaurant from "./Pages/Restaurant";
// import Retail from "./Pages/`9+";
// import Laundry from "./Pages/Laundry";

// Pricing page
// import PriceCRM from "./Pages/PriceCRM";
// import PriceERP from "./Pages/PriceERP";
// import PricePOS from "./Pages/PricePOS";
// import Desktop10 from "./Pages/Desktop10";
// import ThankYou from "./Pages/ThankYou";
// import SubscribesStripe from "./components/SubscribesStripe";
// import ERPLandingPage from "./Pages/ERPLandingPage";
// import ThankYouERP from "./Pages/ThankYouERP";
// import Contacts from "./components/Contacts";

//Lazy loading of components starts here...
const HomeERP = React.lazy(() => import("./Pages/Home-ERP"))
const About = React.lazy(() => import("./Pages/About"));

const Team = React.lazy(() => import("./Pages/Team"));
// const BusinessERP = React.lazy(() => import("./Pages/BusinessERP"));
// const BusinessCRM = React.lazy(() => import("./Pages/BusinessCRM"));
// const BusinessPOS = React.lazy(() => import("./Pages/BusinessCRM"));
const ProductERP = React.lazy(() => import("./Pages/ProductERP"));
const ProductPOS = React.lazy(() => import("./Pages/ProductPOS"));
const ProductRMS = React.lazy(() => import("./Pages/ProductRMS"));

const Trail = React.lazy(() => import("./Pages/Trail"));
const Bloglist = React.lazy(() => import("./Pages/Bloglist"));

// Blog-Detail
const CrmBlogDetail = React.lazy(() => import("./Pages/CrmBlogDetail"));
const ErpBlogDetail = React.lazy(() => import("./Pages/ErpBlogDetail"));
const PosBlogDetail = React.lazy(() => import("./Pages/PosBlogDetail"));

// Features-ERP
const Administration = React.lazy(() => import("./Pages/Administration"));
const Hrms = React.lazy(() => import("./Pages/Hrms"));
const AccountAndFinance = React.lazy(() => import("./Pages/AccountAndFinance"));
const PurchasingAndOperation = React.lazy(() => import("./Pages/PurchasingAndOperation"));
const Sale = React.lazy(() => import("./Pages/Sales"));
const InventoryAndManagement = React.lazy(() => import("./Pages/InventoryAndManagement"));
const ProjectAndManagement = React.lazy(() => import("./Pages/ProjectAndManagement"));
const Pos = React.lazy(() => import("./Pages/Pos"));
const SalesERP = React.lazy(() => import("./Pages/SalesERP"));

// features-CRM
const Sales = React.lazy(() => import("./Pages/Sales"));
const Support = React.lazy(() => import("./Pages/Support"));
const RealAndEstate = React.lazy(() => import("./Pages/RealAndEstate"));
const Shipping = React.lazy(() => import("./Pages/Shipping"));

const POSSubscribes = React.lazy(() => import("./components/POSSubscribesStripe"));
const Faq = React.lazy(() => import("./Pages/Faq"));
const ServiceDetails = React.lazy(() => import("./Pages/ServiceDetails"));
const SignIn = React.lazy(() => import("./Pages/SignIn"));
const SignUp = React.lazy(() => import("./Pages/SignUp"));
// const BlogGridPage = React.lazy(()=> import("./Pages/BlogGridPage")) ;
const NotFound = React.lazy(() => import("./Pages/404"));
const ForgetPass = React.lazy(() => import("./components/ForgetPass"));
const PrivacyPolicy = React.lazy(() => import("./components/PrivacyPolicy"));
const TermAndConditions = React.lazy(() => import("./components/TermAndConditions"));
const RefundPolicy = React.lazy(() => import("./components/RefundPolicy"));
const TrialPolicy = React.lazy(() => import("./components/TrialPolicy"));

// Features-POS
const Restaurant = React.lazy(() => import("./Pages/Restaurant"));
const Retail = React.lazy(() => import("./Pages/`9+"));
const Laundry = React.lazy(() => import("./Pages/Laundry"));


// Pricing page
const PriceCRM = React.lazy(() => import("./Pages/PriceCRM"));
const PriceERP = React.lazy(() => import("./Pages/PriceERP"));
const PricePOS = React.lazy(() => import("./Pages/PricePOS"));
const PriceRMS = React.lazy(() => import("./Pages/PriceRMS"));
const Desktop10 = React.lazy(() => import("./Pages/Desktop10"));
const ThankYou = React.lazy(() => import("./Pages/ThankYou"));
const SubscribesStripe = React.lazy(() => import("./components/SubscribesStripe"));
const RMSSubscribes = React.lazy(() => import("./components/RMSSubscribes"));
const ERPLandingPage = React.lazy(() => import("./Pages/ERPLandingPage"));
const ThankYouERP = React.lazy(() => import("./Pages/ThankYouERP"));
const Contacts = React.lazy(() => import("./components/Contacts"));

//New landing pages
const LatestERPLandingPage = React.lazy(() => import("./Pages/LandingPages/ERPLandingPage/ERPLandingPage"));
const LatestERPThankYouPage = React.lazy(() => import("./Pages/LandingPages/ERPLandingPage/ERPThankkYouPage"));
const LatestPOSLandingPage = React.lazy(() => import("./Pages/LandingPages/ERPLandingPage/POSLandingPage"));
const LatestPOSThankYouPage = React.lazy(() => import("./Pages/LandingPages/ERPLandingPage/POSThankYouPage"));
const RMSLandingPage = React.lazy(() => import("./Pages/LandingPages/ERPLandingPage/RMSLandingPage"));
const RMSThankYouPage = React.lazy(() => import("./Pages/LandingPages/ERPLandingPage/RMSThankYouPage"));


function App(props) {
  // componentDidMount() {
  //   this.props.hideLoader();
  // }
  // render() {

  useEffect(() => {
    props.hideLoader();
  }, [props]);

  const Wrapper = ({ children }) => {
    const location = useLocation();
    useLayoutEffect(() => {
      document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);
    return children
  }

  return (
    <Router>
      <Suspense fallback={<LazyLoader />}>
        <Wrapper>
          <Routes>
            <Route exact path="/" element={
              // <HomeERP />
              <Suspense fallback={<LazyLoader />}>
                <HomeERP props={{ props }} />
              </Suspense>
            } />
            {/* <Route
            path="/Digital-marketing"
            element={DigitalMarketing}
          />
          <Route
            path="/Payment-processing"
            element={PaymentProcessing}
          />
          <Route path="/HR-Management" element={HRManagement} />
          <Route path="/Startup" element={Startup} />
          <Route path="/Home-CRM" element={HomeCRM} /> */}
            <Route path="/About" element={<Suspense fallback={<LazyLoader />}>
              <About props={{ props }} />
            </Suspense>} />
            {/* <Route path="/Service" element={Service} /> 
          <Route path="/Process" element={Process} /> */}
            <Route path="/Team" element={Team} />
            {/* <Route path="/BusinessERP" element={<Suspense fallback={<LazyLoader />}>
              <BusinessERP props={{ props }} /> 
            </Suspense>} /> */}
            <Route path="/BusinessERP" element={<Suspense fallback={<LazyLoader />}>
              <ProductERP props={{ props }} />
            </Suspense>} />
            {/* <Route path="/BusinessPOS" element={<Suspense fallback={<LazyLoader />}>
              <BusinessPOS props={{ props }} /> 
            </Suspense>} /> */}
            <Route path="/BusinessPOS" element={<Suspense fallback={<LazyLoader />}>
              <ProductPOS props={{ props }} />
            </Suspense>} />
            {/* <Route path="/BusinessCRM" element={<Suspense fallback={<LazyLoader />}>
              <BusinessCRM props={{ props }} />
            </Suspense>} /> */}
            <Route path="/BusinessRMS" element={<Suspense fallback={<LazyLoader />}>
              <ProductRMS props={{ props }} />
            </Suspense>} />

            <Route path="/Trail" element={<Suspense fallback={<LazyLoader />}>
              <Trail props={{ props }} />
            </Suspense>} />
            <Route path="/Bloglist" element={<Suspense fallback={<LazyLoader />}>
              <Bloglist props={{ props }} />
            </Suspense>} />
            {/* Blog-Details */}
            <Route path="/CrmBlogDetail" element={<Suspense fallback={<LazyLoader />}>
              <CrmBlogDetail props={{ props }} />
            </Suspense>} />
            <Route path="/ErpBlogDetail" element={<Suspense fallback={<LazyLoader />}>
              <ErpBlogDetail props={{ props }} />
            </Suspense>} />
            <Route path="/PosBlogDetail" element={<Suspense fallback={<LazyLoader />}>
              <PosBlogDetail props={{ props }} />
            </Suspense>} />

            {/* Features */}
            <Route path="/SalesERP" element={<Suspense fallback={<LazyLoader />}>
              <SalesERP props={{ props }} />
            </Suspense>} />
            <Route path="/Hrms" element={<Suspense fallback={<LazyLoader />}>
              <Hrms props={{ props }} />
            </Suspense>} />
            <Route
              path="/AccountAndFinance"
              element={<Suspense fallback={<LazyLoader />}>
                <AccountAndFinance props={{ props }} />
              </Suspense>}
            />

            <Route
              path="/PurchasingAndOperation"
              element={<Suspense fallback={<LazyLoader />}>
                <PurchasingAndOperation props={{ props }} />
              </Suspense>}
            />
            <Route path="/Sale" element={Sale} />
            <Route
              path="/InventoryAndManagement"
              element={<Suspense fallback={<LazyLoader />}>
                <InventoryAndManagement props={{ props }} />
              </Suspense>}
            />
            <Route
              path="/ProjectAndManagement"
              element={<Suspense fallback={<LazyLoader />}>
                <ProjectAndManagement props={{ props }} />
              </Suspense>}
            />
            <Route path="/Posfeature" element={<Suspense fallback={<LazyLoader />}>
              <Pos props={{ props }} />
            </Suspense>} />

            {/* <Route path="/BlogSingle" element={BlogSingle} />
          <Route path="/BlogGridPage" element={BlogGridPage} /> */}
            <Route path="/Contact" element={<Suspense fallback={<LazyLoader />}>
              <Contacts props={{ props }} />
            </Suspense>} />
            {/* <Route path="/Landing" element={Landing} /> */}
            <Route path="/ForgetPass" element={<Suspense fallback={<LazyLoader />}>
              <ForgetPass props={{ props }} />
            </Suspense>} />
            {/* Polices page */}
            <Route path="/PrivacyPolicy" element={<Suspense fallback={<LazyLoader />}>
              <PrivacyPolicy props={{ props }} />
            </Suspense>} />
            <Route
              path="/TermAndConditions"
              element={<Suspense fallback={<LazyLoader />}>
                <TermAndConditions props={{ props }} />
              </Suspense>}
            />
            <Route path="/RefundPolicy" element={<Suspense fallback={<LazyLoader />}>
              <RefundPolicy props={{ props }} />
            </Suspense>} />
            <Route path="/TrialPolicy" element={<Suspense fallback={<LazyLoader />}>
              <TrialPolicy props={{ props }} />
            </Suspense>} />
            <Route path="/Administration" element={<Suspense fallback={<LazyLoader />}>
              <Administration props={{ props }} />
            </Suspense>} />

            <Route path="/erp-landing-page" element={<Suspense fallback={<LazyLoader />}>
              <LatestERPLandingPage props={{ hide: true }} />
            </Suspense>} />

            <Route path="/erp-thank-you" element={<Suspense fallback={<LazyLoader />}>
              <LatestERPThankYouPage />
            </Suspense>} />

            <Route path="/pos-landing-page" element={<Suspense fallback={<LazyLoader />}>
              <LatestPOSLandingPage props={{ hide: true }} />
            </Suspense>} />

            <Route path="/pos-thank-you" element={<Suspense fallback={<LazyLoader />}>
              <LatestPOSThankYouPage props={{ hide: true }} />
            </Suspense>} />

            <Route path="/rms-landing-page" element={<Suspense fallback={<LazyLoader />}>
              <RMSLandingPage props={{ hide: true }} />
            </Suspense>} />

            <Route path="/rms-thank-you" element={<Suspense fallback={<LazyLoader />}>
              <RMSThankYouPage props={{ hide: true }} />
            </Suspense>} />


            {/* <Route path="/home-support" element={homesupport} /> 
          <Route path="/HomeHosting" element={homeHosting} /> */}
            <Route path="/Home-ERP" element={<Suspense fallback={<LazyLoader />}>
              <HomeERP props={{ props }} />
            </Suspense>} />
            {/* <Route path="/HomeSecurity" element={homeSecurity} /> */}
            {/* <Route
            path="/HomeAppShowcase"
            element={HomeAppShowcase}
          />
          <Route
            path="/Home-software-dark"
            element={homeSoftwareDark}
          /> */}
            {/* <Route path="/Home-cloud" element={HomeCloud} />
          <Route path="/Home-Tracking" element={HomeTracking} /> RMSSubscribes
          <Route path="/Home-event" element={HomeEvent} />
          <Route path="/Home-chat" element={HomeChat} /> */}

            <Route path="/Subscribes" element={<Suspense fallback={<LazyLoader />}>
              <SubscribesStripe props={{ props }} />
            </Suspense>} />

            <Route path="/RMSSubscribes" element={<Suspense fallback={<LazyLoader />}>
              <RMSSubscribes props={{ props }} />
            </Suspense>} />

            <Route path="/Faq" element={<Suspense fallback={<LazyLoader />}>
              <Faq props={{ props }} />
            </Suspense>} />
            <Route path="/ServiceDetails" element={<Suspense fallback={<LazyLoader />}>
              <ServiceDetails props={{ props }} />
            </Suspense>} />
            <Route path="/SignIn" element={<Suspense fallback={<LazyLoader />}>
              <SignIn props={{ props }} />
            </Suspense>} />
            <Route path="/SignUp" element={<Suspense fallback={<LazyLoader />}>
              <SignUp props={{ props }} />
            </Suspense>} />

            {/* Features-CRM */}
            <Route path="/Sales" element={<Suspense fallback={<LazyLoader />}>
              <Sales props={{ props }} />
            </Suspense>} />
            <Route path="/Support" element={<Suspense fallback={<LazyLoader />}>
              <Support props={{ props }} />
            </Suspense>} />
            <Route path="/RealAndEstate" element={<Suspense fallback={<LazyLoader />}>
              <RealAndEstate props={{ props }} />
            </Suspense>} />
            <Route path="/Shipping" element={<Suspense fallback={<LazyLoader />}>
              <Shipping props={{ props }} />
            </Suspense>} />

            {/* Features-POS */}
            <Route path="/Restaurant" element={<Suspense fallback={<LazyLoader />}>
              <Restaurant props={{ props }} />
            </Suspense>} />
            <Route path="/Retail" element={<Suspense fallback={<LazyLoader />}>
              <Retail props={{ props }} />
            </Suspense>} />
            <Route path="/Laundry" element={<Suspense fallback={<LazyLoader />}>
              <Laundry props={{ props }} />
            </Suspense>} />

            {/* Pricing pages */}
            <Route path="/PriceCRM" element={<Suspense fallback={<LazyLoader />}>
              <PriceCRM props={{ props }} />
            </Suspense>} />
            <Route path="/PriceERP" element={<Suspense fallback={<LazyLoader />}>
              <PriceERP props={{ props }} />
            </Suspense>} />
            <Route path="/PricePOS" element={<Suspense fallback={<LazyLoader />}>
              <PricePOS props={{ props }} />
            </Suspense>} />

            <Route path="/PriceRMS" element={<Suspense fallback={<LazyLoader />}>
              <PriceRMS props={{ props }} />
            </Suspense>} />

            <Route path="/BuyPOS" element={<Suspense fallback={<LazyLoader />}>
              <POSSubscribes props={{ props }} />
            </Suspense>} />
            <Route path="/pos" element={<Suspense fallback={<LazyLoader />}>
              <Desktop10 props={{ props }} />
            </Suspense>} />
            <Route path="/erp" element={<Suspense fallback={<LazyLoader />}>
              <ERPLandingPage props={{ props }} />
            </Suspense>} />
            <Route path="/thankyou" element={<Suspense fallback={<LazyLoader />}>
              <ThankYou props={{ props }} />
            </Suspense>} />
            <Route path="/thankyou-erp" element={<Suspense fallback={<LazyLoader />}>
              <ThankYouERP props={{ props }} />
            </Suspense>} />

            <Route path="*" element={<NotFound />} />
          </Routes>
        </Wrapper>
      </Suspense>
    </Router>
  );
}
// }

export default App;
